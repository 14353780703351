import { ReportHeader } from 'modules/management/models/report-header';

export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Returns a new instance of the model
     *
     * @returns {ReportHeader}
     */
    model() {
        let model = new ReportHeader();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.title = {
            type:       'text',
            key:        'title',
            label:      'form.field.title',
            size:       12,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.code = {
            type:       'text',
            key:        'code',
            label:      'form.field.code',
            size:       6,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.report_model = {
            type:       'text',
            key:        'model',
            label:      'form.field.model',
            size:       6,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.revision = {
            type:       'text',
            key:        'revision',
            label:      'form.field.revision',
            size:       6,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.date = {
            type:       'material-ui-date-picker',
            key:        'date',
            label:      'form.field.date',
            size:       6,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.effectiveDate = {
            type:       'material-ui-date-picker',
            key:        'effective_date',
            label:      'form.field.effective-date',
            size:       6,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.effectiveDate = {
            type:       'material-ui-date-picker',
            key:        'effective_date',
            label:      'form.field.effective-date',
            size:       6,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.image_file_id = {
            type:       'file-dropzone',
            key:        'image_file_id',
            label:      'form.field.image',
            size:       12,
            url:        viewModel.filesRepository.uploadUri(),
            hidden:     readonly,
            mockFile:   viewModel.model.existing_image_file,
            attributes: {
                disabled: readonly,
            },
        };

        this.observations = {
            type:       'textarea',
            key:        'observations',
            label:      'form.field.observations',
            required:   false,
            size:       12,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        const schema = [
            [this.title],
            [this.code],
            [this.report_model],
            [this.revision],
            [this.date, this.effectiveDate],
            [this.image_file_id],
            [this.observations],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.appContainer.router.navigateToRoute('management.report-categories.index',
                { categoryId: viewModel.reportCategory.id },
            ),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
